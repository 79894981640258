export const faq = [
  {
    title: 'Was kostet ein Schrank?',
    content: 'Da jeder Maßschrank ein Einzelstück und individuell für jeden Kunden an dessen Bedürfnisse angepasst ist, sowie individuell kalkuliert, können wir keine Pauschalpreise nennen. Vereinbaren Sie  einfach einen kostenfreien Planungstermin auf unserer Homepage und wir melden uns zeitnah telefonisch bei Ihnen, besprechen Ihr Projekt und können Ihnen dann auch schon einen ersten Preis nennen.'
  },
  {
    title: 'Wie lange dauert es, bis der Schrank bei mir eingebaut ist?',
    content: 'Wenn der Auftrag zustande kommt, dauert es aktuell ungefähr 7-10 Wochen, bis der Schrank geliefert und eingebaut wird.'
  },
  {
    title: 'Wo sind wir überall vertreten?',
    content: 'Sie finden uns in unseren Ausstellungsräumen in Wuppertal, Engelskirchen und Mönchengladbach. NRW weit kümmern wir uns um Ihr individuelles Anliegen.'
  },
  {
    title: 'Was kostet der Beratungstermin und das Aufmaß?',
    content: 'Des Aufmaß sowie die persönliche Beratung und die Montage vor Ort gehören zu unserem Rundum-Service, weshalb hier in der Regel keine weiteren Kosten anfallen.'
  },
  {
    title: 'Wie läuft der Beratungs-/Planungstermin ab?',
    content: 'Einer unserer Gestaltungsberater  kommt zu Ihnen nach Hause und bespricht mit Ihnen Ihr Schrankprojekt. Im Gepäck hat er viele verschiedene Muster und Anschauungsmaterialien, damit Sie sich von der verwendeten Qualität überzeugen können. Dabei wird Ihnen gezeigt  was möglich ist und steht Ihnen mit Tipps und Tricks zur Seite. Anschließend erledigt er das professionelle Aufmaß auf den Millimeter genau, damit auch kein Stauraum verschenkt wird. Ist alles perfekt vermessen und die gewünschte Schrankausstattung ausgewählt, kann die finale Kostenkalkulation mit einem Festpreis direkt bei Dir vor Ort erfolgen.'
  },
  {
    title: 'Muss ich mich auf den Planungstermin vorbereiten?',
    content: 'Um den Planungstermin perfekt zu gestalten ist es bei Neubauten wichtig, dass der Estrich bereits vorhanden ist, um ein perfektes Aufmaß zu garantieren. Bei Bestandsimmobilien sollte der Raum bzw. der Ort, an dem der Schrank stehen soll, zugänglich sein. Eine andere Vorbereitung ist nicht nötig.'
  },
  {
    title: 'Ich habe Sonderwünsche – werden diese berücksichtigt?',
    content: 'Genau dafür gibt es uns! (Fast) jeder noch so kleine Sonderwunsch, jedes Sondermaß oder auch ganz individuelle Farb- und Dekorvarianten sind unser Spezialgebiet. Ihr Schrank soll so werden wie Sie es sich wünschen, damit er perfekt in Ihr Zuhause passt und Sie ein Leben lang glücklich macht!'
  },
  {
    title: 'Wo werden die Schränke gefertigt?',
    content: 'Planung und Ausführung kommen aus eigenem Haus. Die Produktion der verwendeten Materialien findet in Polen statt.'
  },
  {
    title: 'Welche Materialien werden für die Schränke verwendet?',
    content: 'Wir verwenden nur hochwertigste Grundträgerplatten und Beschichtungen in Schreinerqualität. Normalerweise kennt man Plattenstärken von 12 oder 16mm – wir verwenden ausschließlich 18mm oder 36mm starke Platten, um maximale Stabilität und Langlebigkeit zu garantieren. Wenn wir Rückwände einbauen  sind die meist 10mm stark, in seltenem Fällen 4mm HDF.'
  },
  {
    title: 'Kann ich auch Beleuchtung in meinen Schrank einbauen?',
    content: 'Natürlich! Der Traum Schrank kann mit längst aufgesetzter LED-Alu-Lichtleiste ausgestattet werden, so ist das Licht auf beiden Seiten gut verteilt.'
  },
  {
    title: 'Ich habe noch alte Schränke – muss ich diese selbst abbauen?',
    content: 'Nein. Wir bieten auch den Abbau und die Entsorgung alter Schränke, oder sonstigen Einrichtungsgegenstände an. Bitte beachten Sie, dass für den Abbau und die Entsorgung eine kleine Servicegebühr berechnet wird.'
  },
  {
    title: 'Wie erfolgt die Lieferung?',
    content: 'Die Lieferung gehört zu unserem Service dazu! Sie müssen sich um nichts kümmern. Unsere Monteure bringen Ihren Schrank einfach zum Einbautermin mit.'
  },
  {
    title: 'Wie lange dauert der Einbau?',
    content: 'Wir kümmern uns darum, dass Ihr Traumschrank in kürzester Zeit aber dennoch mit maximaler Sorgfalt eingebaut wird. Je nach Größe und Umfang des Projekts kann es von ½ Tag bis zu maximal 2 Tage dauern, bis alle Ihre Einbaulösungen nach Maß fertig eingebaut sind. In der Regel sind wir nach einem Tag fertig.'
  },
  {
    title: 'Wie erfolgt die Bezahlung?',
    content: 'Bei erfolgreichem Auftragsabschluss erfolgt eine bis zu 40%-ige Anzahlung – der Restbetrag wird nach Fertigstellung und erstelltem Abnahmeprotokolls einfach direkt bei unseren Monteuren bezahlt. Bei uns können sie Bar oder per Überweisung zahlen.'
  },
]