import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box
}            from '@chakra-ui/react'

import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import { FaQuestionCircle } from "react-icons/fa";

import { faq } from '../data/faq';

const Faq = () => {
  return (
    <Wrapper>
      <Spacer/>
      <Heading icon={<FaQuestionCircle />}>FAQ</Heading>
      <Accordion>
        {faq.map(item => (
          <AccordionItem sx={{
            h2: {
              marginBottom: 0
            }
          }}>
            <h2>
              <AccordionButton p={6}>
                <Box flex="1" textAlign="left">
                  {item.title}
                </Box>
                <AccordionIcon/>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p>{item.content}</p>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Spacer />
    </Wrapper>
  )
};

export default Faq;